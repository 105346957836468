/*****************************************************************************
 * UmanIT - Slider des produits en mobile
 *****************************************************************************/

require("slick-carousel");

const offersList = $("[data-slider='products']");
let isInit = false;

// Initialisation
handleOffersSlider();

// Resize de la fenêtre
window.addEventListener("resize", function() {
  setTimeout(function(){handleOffersSlider();}, 200);
});

/**
 * Construction du slider en mobile (et destruction en desktop
 * -> https://github.com/kenwheeler/slick/issues/542#issuecomment-168658956
 * return {void}
 */
function handleOffersSlider() {
  if(offersList) {
    if(breakpoints.isMobile() && !isInit) {
      offersList.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        arrows: false,
        infinite: false,
        dots: true
      });

      isInit = true;
    } else if(breakpoints.isDesktop() && isInit) {
      offersList.slick('unslick');
      isInit = false;
    }
  }
}
